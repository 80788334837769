<script setup lang="ts">
import { AlertProps } from "./Alert.props"
import { pushHeaderClickCtaEvent } from "~/utils/analytics/clickCta"

withDefaults(defineProps<AlertProps>(), {
  backgroundColor: "#000000",
  textColor: "#ffffff"
})

const getLink = () => {
  return document.querySelector(
    ".bottom-header .markdown__link-underlined.markdown__link-mouse-medium > a"
  )
}

const pushGtm = (evt: Event) => {
  const text = (evt.target as HTMLAnchorElement)?.text
  pushHeaderClickCtaEvent(text)
}

onMounted(() => {
  const link = getLink()
  link?.addEventListener("click", (evt) => pushGtm(evt))
})

onUnmounted(() => {
  const link = getLink()
  link?.removeEventListener("click", (evt) => pushGtm(evt))
})
</script>

<template>
  <div
    class="bottom-header px-2 py-2 text-center"
    :style="{
      backgroundColor: backgroundColor
    }"
  >
    <UtilsMarkdown
      :content="text"
      :style="{
        color: textColor
      }"
      class="
        mouse-bold
        markdown__link-underlined markdown__link-mouse-medium
        line-clamp-2
      "
    />
  </div>
</template>
