<script setup lang="ts">
import { SideDiscountsProps } from "./SideDiscounts.props"
import { customPaths } from "~/utils/constants"
import { on } from "node:events"

defineProps<SideDiscountsProps>()
const emit =
  defineEmits<{
    (e: "onClose"): void
    (e: "onClickCTA", index: number): void
  }>()

const onClickDiscountCode = (index: number) => {
  emit("onClose")
  emit("onClickCTA", index)
}

const isLogged = useIsLogged()
const { t } = useI18n()
const discountSidebar = ref<HTMLElement>()

// @ts-ignore
const widthSidebar = ref(
  discountSidebar.value?.parentElement?.getBoundingClientRect().width ?? 0
)

const { width: widthWindow } = useWindowSize()

watch(widthWindow, () => {
  widthSidebar.value =
    discountSidebar.value?.parentElement?.getBoundingClientRect().width ?? 0
})
onMounted(() => {
  widthSidebar.value =
    discountSidebar.value?.parentElement?.getBoundingClientRect().width ?? 0
})
const promotionsCategoriesAndBrands = [
  {
    description: t("promoCodes.brand.description"),
    note: t("promoCodes.brand.note"),
    bgImage: getImageData({
      altText: "Placeholder",
      url: "/i5oh5hzamkwo/5BdhjKtgwQyxeNfWfs7rkp/865dcc91be61c8c55c4664e62ca3c08e/discount-banner-blue.svg",
      width: 400,
      height: 200
    }),
    buttonLabel: t("promoCodes.brand.buttonLabel"),
    buttonPath: "sconti#codici-sconto-marche"
  },
  {
    description: t("promoCodes.category.description"),
    note: t("promoCodes.category.note"),
    bgImage: getImageData({
      altText: "Placeholder",
      url: "/i5oh5hzamkwo/6Q9MxHOJf8vCxARY87QYEe/20f7fb09576193c6aa1dfbc2b4dff6bb/discount-banner-orange.svg",
      width: 400,
      height: 200
    }),
    buttonLabel: t("promoCodes.category.buttonLabel"),
    buttonPath: "sconti#codici-sconto-categorie"
  }
]
</script>

<template>
  <div class="discount-side" ref="discountSidebar">
    <div
      class="
        cta-container
        fixed
        bottom-0
        right-0
        z-20
        hidden
        w-auto
        bg-white
        px-6
        py-4
        shadow-01
        md:block
      "
      :style="widthSidebar ? `width: ${widthSidebar}px` : null"
    >
      <UtilsButton
        theme="link-green"
        :text="$t('discounts.side.bottomButton')"
        class="w-full"
        order="last"
        :path="customPaths.discounts"
        @click="$emit('onClose')"
      >
        <template #icon> <IconsArrowRight class="h-5 w-5" /> </template>
      </UtilsButton>
    </div>
    <div class="flex flex-col justify-between">
      <div class="relative z-10 mb-20">
        <div class="organisms-side_discounts">
          <div class="pl-7 pt-6">
            <div class="mb-5 flex items-center justify-between">
              <p class="hyppo-bold">{{ $t("discounts.side.title") }}</p>
              <UtilsIcon
                name="Close.svg"
                color="green-main"
                @click="$emit('onClose')"
                class="h-6 w-6 cursor-pointer text-green-main"
              />
            </div>
            <div
              v-for="(discount, index) in discounts"
              :key="`discount-code-${discount.discountCode}`"
              class="mb-6"
            >
              <CardDiscountCode
                v-bind="discount"
                @on-click-c-t-a="onClickDiscountCode(index)"
                :is-big="false"
                class="-ml-4 md:ml-0"
              />
            </div>
          </div>
        </div>
        <div
          v-if="isLogged && promotionsCategoriesAndBrands"
          class="other-codes py-6 pl-7"
        >
          <div class="promotion-wrapper border-t-[1px] border-slate-20">
            <div
              class="promotions my-6 flex flex-wrap"
              v-for="(promotion, index) in promotionsCategoriesAndBrands"
              :key="`promotion-${index}`"
            >
              <CardDiscountDiscover
                v-bind="promotion"
                @on-close="$emit('onClose')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
