<script setup lang="ts">
import { useWindowSize, useElementSize } from "@vueuse/core"
import TheFooter from "./TheFooter.server.vue"

const { footerInfo } = await useFooterConnect()
const { t } = useI18n()
const { closeModal } = useModal()

const welcomeID = ref<string>("WELCOME_MODAL")

const wrapperElement = ref(null)
const { height: wrapperHeight } = useElementSize(wrapperElement)

const { height: screenHeight } = useWindowSize()

const welcomeModal = {
  bgImage: getImageData({
    altText: t("homepage.welcomeModal.title"),
    url: "/i5oh5hzamkwo/Iu4GnahckmTPhP1TiezMs/284b6f8481fe70de70b1172b94c3a769/cover-image-welcome-modal.webp",
    width: 400,
    height: 200
  }),
  title: t("homepage.welcomeModal.title"),
  text: t("homepage.welcomeModal.text")
}

const closeModalWelcome = () => {
  closeModal(welcomeID.value)
}
</script>

<template>
  <div class="footer">
    <TheFooter v-if="footerInfo" v-bind="footerInfo" />
    <DialogsAndModalsUtilsModal :id="welcomeID" is-welcome-modal>
      <div
        :class="{
          'h-screen overflow-auto': wrapperHeight >= screenHeight
        }"
      >
        <div ref="wrapperElement">
          <DialogsAndModalsWelcome
            :="welcomeModal"
            :id-modal="welcomeID"
            @on-close="closeModalWelcome"
          />
        </div>
      </div>
    </DialogsAndModalsUtilsModal>
  </div>
</template>
