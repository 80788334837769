<script setup lang="ts">
import { NoResultsProps } from "./NoResults.props"

defineProps<NoResultsProps>()

const emit =
  defineEmits<{
    (e: "onClickCta"): void
  }>()
</script>

<template>
  <div class="dolphin-bold">
    {{ $t("searchbar.noResults", { searchTerm: searchTerm }) }}<br />
    <AppLink
      v-if="cta"
      :to="cta"
      class="underline decoration-1 underline-offset-2"
      @click="emit('onClickCta')"
    >
      {{ ctaText ? ctaText : cta }}
    </AppLink>
  </div>
</template>
