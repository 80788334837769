import Flash from "~icons/icons/Flash.svg"
import EmptyHeart from "~icons/icons/EmptyHeart.svg"
import GiftFilled from "~icons/icons/GiftFilled.svg"
import Fire from "~icons/icons/Fire.svg"
import Bookmark from "~icons/icons/Bookmark.svg"

export type IconType = keyof typeof iconsMap

export const iconsMap = {
  flash: Flash,
  giftFilled: GiftFilled,
  fire: Fire,
  bookmark: Bookmark,
  heart: EmptyHeart
}
