<script setup lang="ts">
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"
import { WelcomeProps } from "./Welcome.props"

defineProps<WelcomeProps>()
const emit = defineEmits<{ (e: "onClose"): void }>()

const handleClick = () => {
  emit("onClose")
  navigateToLoginSignupPage()
}
</script>

<template>
  <DialogsAndModalsStandard
    :title="$t('dialogsAndModals.welcome.welcome')"
    class="mx-auto max-w-[95vw] md:max-w-[760px] lg:max-w-[970px]"
    @on-close="$emit('onClose')"
  >
    <template #content>
      <div class="relative z-10 overflow-hidden py-6 text-center">
        <UtilsWrapperImage
          v-if="bgImage"
          :="bgImage"
          :key="bgImage.image.url"
          class="wrapper-image-full-container-centered absolute inset-0 -z-10"
        />
        <h1
          v-if="title"
          class="hyppo-bold md:mammoth-bold m-auto max-w-full md:max-w-1/2"
        >
          {{ title }}
        </h1>
      </div>

      <div class="w-full bg-white">
        <div
          class="
            m-auto
            flex
            max-w-prose
            flex-col
            items-center
            p-4
            text-center
            md:max-w-full
            md:px-10
            md:py-6
          "
        >
          <UtilsMarkdown
            v-if="text"
            :content="text"
            class="mouse md:beaver mb-2 md:mb-6"
          />

          <UtilsButton
            :text="$t('dialogsAndModals.welcome.visitWebsite')"
            @click="$emit('onClose')"
            class="cursor-pointer"
          />
        </div>
      </div>

      <div
        class="
          flex flex-col
          items-center
          bg-grey-main
          px-4
          py-4
          text-center
          md:py-6
        "
      >
        <p class="pig-bold md:dolphin-bold">
          {{ $t("dialogsAndModals.welcome.registrationCta") }}
        </p>

        <p class="mouse md:beaver mb-2 mt-4 md:mb-4 md:mt-2">
          {{ $t("dialogsAndModals.welcome.benefit") }}
        </p>

        <UtilsButton
          :text="$t('dialogsAndModals.welcome.registerNow')"
          @click="handleClick"
          theme="outlined-green"
          class="cursor-pointer"
        />
      </div>
    </template>
  </DialogsAndModalsStandard>
</template>
