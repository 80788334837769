<script setup lang="ts">
import { DefaultProps } from "./Default.props"

const props = withDefaults(defineProps<DefaultProps>(), {
  type: "default",
  textColor: "light"
})

const isTypeDefault = computed(() => props.type === "default")
const isTypeMini = computed(() => props.type === "mini")
const isTypeBig = computed(() => props.type === "big")
const isTypeMacro = computed(() => props.type === "macro")

const dynamicId = computed(() => `link-${Math.random()}`)

const triggerLink = () => document.getElementById(dynamicId.value)?.click()
</script>

<template>
  <div
    @click="triggerLink"
    class="
      card-promo-highlight_card
      relative
      cursor-pointer
      overflow-hidden
      rounded
      shadow-01
    "
    :class="{
      'h-[106px] w-[288px] lg:w-[348px]': isTypeMini,
      'h-[228px] w-[288px] lg:w-[348px]': isTypeBig,
      'h-[188px]': isTypeMacro
    }"
  >
    <UtilsWrapperImage
      v-if="image"
      class="wrapper-image-full-container-centered"
      :class="{
        'absolute inset-0 z-0': isTypeMini || isTypeBig || isTypeMacro,
        'wrapper-image-fixed-96': isTypeDefault
      }"
      v-bind="image"
    />

    <div
      class="relative z-10"
      :class="{
        'px-4 py-2': isTypeDefault,
        'flex h-full flex-col items-center justify-center p-2 text-center lg:px-8 lg:py-4':
          isTypeMini || isTypeBig,
        'flex h-full flex-col justify-end px-6 pb-10 ': isTypeMacro
      }"
    >
      <p
        v-if="title"
        :class="[
          {
            '!text-black-main': isTypeDefault,
            'pig-bold': isTypeDefault || isTypeBig,
            'beaver-bold': isTypeMini,
            'hyppo-bold lg:elephant-bold': isTypeMacro
          },
          textColor === 'light' ? 'text-white' : 'text-black-main'
        ]"
      >
        {{ title }}
      </p>

      <UtilsButton
        :id="dynamicId"
        :text="text ?? $t('card.promoCard.discoverOffers')"
        theme="link-black"
        :path="path"
        order="last"
        class="move-left custom-btn-no-padding inline-flex"
        :class="{
          'mt-3 md:mt-4': isTypeDefault,
          'custom-btn-pig-medium custom-btn-green custom-btn-hover-green-300 mt-6':
            isTypeMini || isTypeBig,
          'mt-5': isTypeMacro,
          'custom-btn-white': textColor === 'light' && isTypeMacro
        }"
      >
        <template v-if="!isTypeMacro" #icon>
          <IconsArrowRight class="text-black h-5 w-5" />
        </template>
      </UtilsButton>
    </div>
  </div>
</template>
