<script setup lang="ts">
const { newCouponCandidate, handleApplyDiscountCode } = useDiscountCodes()
const emit =
  defineEmits<{
    (e: "onClose"): void
  }>()

const handleApply = () => {
  handleApplyDiscountCode(newCouponCandidate.value.indexDiscountCode!, true)
  emit("onClose")
}

const handleCancel = () => {
  emit("onClose")
}
</script>

<template>
  <div class="modal-content-wrapper">
    <h3 class="dolphin-bold text-center text-black-neutral">
      {{ $t("dialogsAndModals.changeCoupon.title") }}
    </h3>

    <div>
      <p
        v-html="$t('dialogsAndModals.changeCoupon.description1')"
        class="beaver text-center text-black-neutral"
      ></p>
      <p
        v-html="
          $t('dialogsAndModals.changeCoupon.description2', {
            oldCode: useCart().cart.value?.coupon_code,
            newCode: newCouponCandidate.code
          })
        "
        class="beaver mt-3 text-center text-black-neutral"
      ></p>
    </div>

    <div class="mt-2 flex flex-wrap gap-2">
      <UtilsButton
        @click="handleApply"
        :text="$t('dialogsAndModals.changeCoupon.confirm')"
        class="w-full"
      />

      <UtilsButton
        @click="handleCancel"
        theme="outlined-green"
        :text="$t('dialogsAndModals.changeCoupon.cancel')"
        class="w-full"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.modal-content-wrapper {
  @apply flex w-full flex-col items-center gap-4 rounded-lg bg-white px-4 pb-4 pt-6 shadow-01 md:max-w-[390px] md:px-10 lg:max-w-[430px];
}

</style>
