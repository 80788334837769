<script setup lang="ts">
const { listHelpCards } = useBannerHelp()
const { t } = useI18n()

const emit = defineEmits<{ (e: "onClose"): void }>()

const handleClose = () => {
  emit("onClose")
}
</script>

<template>
  <div class="relative h-full w-full">
    <div class="absolute left-0 top-0 h-full w-full"></div>
    <div
      class="
        banner-help-vertical_need_help
        relative
        hidden
        h-full
        w-full
        pb-8
        pt-6
        md:block
        md:pl-8
      "
    >
      <div class="mb-5 flex items-center justify-between">
        <p class="hyppo-bold">
          {{ $t("homepage.bannerCustomerService.title") }}
        </p>

        <UtilsIcon
          name="Close.svg"
          color="green-main"
          @click="handleClose"
          class="h-6 w-6 cursor-pointer text-green-main"
        />
      </div>

      <div class="pb-4 pr-3">
        <BannerHelpUtilityCard
          v-for="(item, index) in listHelpCards"
          :key="`help-${index}`"
          class="mb-4 rounded-lg shadow-01"
          v-bind="item"
          @on-close="handleClose"
        />
      </div>
    </div>
  </div>
</template>
