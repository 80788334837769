<script setup lang="ts">
import { MenuItem, TheHeaderProps } from "./TheHeader.props"
import { iconsMap } from "./TheHeader.iconMapping"
import { customPaths } from "~/utils/constants"
import { navigateToFindOrder } from "~/utils/navigateToFindOrder"
import { vElementVisibility } from "@vueuse/components"
import { useElementHover } from "@vueuse/core"
import { appPaths } from "~/utils/paths/paths"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"
import { pushMenuClickCtaEvent } from "~/utils/analytics/clickCta"

const props = withDefaults(defineProps<TheHeaderProps>(), {
  statusMiniCart: false
})

const route = useRoute()

const isLoading = ref(true)

const isLogged = useIsLogged()

const currentPath = computed(() => (route ? route.path : ""))
const isHomePage = computed(() => (currentPath.value !== "/" ? true : false))

const showSearchBarRef = ref(props.showSearchBar)

const isHeaderVisible = useState("header", () => true)
const isHeaderOnHover = ref(false)

const onElementVisibility = (state: boolean) => {
  isHeaderVisible.value = state
}

const { isOpen: isPopupVisible } = useUIState("focus-search")

const desktopNavigationArea = ref()
const loginArea = ref()
const dividerArea = ref()
const isDesktopNavigationAreaOnHover = useElementHover(desktopNavigationArea)
const isLoginAreaOnHover = useElementHover(loginArea)
const isDividerAreaOnHover = useElementHover(dividerArea)

const emit =
  defineEmits<{
    (e: "onOpenMenu", index?: number): void
    (e: "onCloseMenuMega"): void
    (e: "onOpenMenuSide"): void
    (e: "onOpenMiniCart"): void
    (e: "onOpenDiscounts"): void
    (e: "onOpenSupport"): void
  }>()

const handleClickAvatar = () => {
  navigateTo(customPaths.profile.root)
}

const isMenuAlreadyOnHover = ref(false)

const hoverElement = ref(null)
const hoverTimeout = ref()

const startHover = (itemMenu: MenuItem, indexMenu: number) => {
  if (isMenuAlreadyOnHover.value) {
    onHoverMenu(itemMenu, indexMenu)
  }

  hoverTimeout.value = setTimeout(() => {
    // The action start after 0.5 second of hover
    onHoverMenu(itemMenu, indexMenu)
  }, 500)
}

const endHover = () => {
  // reset the hover
  clearTimeout(hoverTimeout.value)
}

const isNavigationToCategoryStarted = ref(false)

const handleGtm = (label: string) => {
  pushMenuClickCtaEvent(label)
}

const handleGoToCategory = (label: string) => {
  emit("onCloseMenuMega")
  handleGtm(label)

  isNavigationToCategoryStarted.value = true

  setTimeout(() => {
    isNavigationToCategoryStarted.value = false
  }, 1000)
}

const onHoverMenu = (itemMenu: MenuItem, indexMenu: number) => {
  if (itemMenu.subcategories == null) {
    // if the hovered item does not contain any subcategory, it shouldn't open the megamenu and categories
    emit("onCloseMenuMega")
  } else if (!isNavigationToCategoryStarted.value) {
    isMenuAlreadyOnHover.value = true
    emit("onOpenMenu", indexMenu)
  }
}

const handleOpenSideBar = () => {
  showSearchBarRef.value = true
  emit("onOpenMenuSide")
}

const handleOpenDiscount = (label: string) => {
  emit("onOpenDiscounts")
  pushMenuClickCtaEvent(label)
}

const handleOpenNeedHelp = (label: string) => {
  emit("onOpenSupport")
  pushMenuClickCtaEvent(label)
}

watch(isHeaderOnHover, (newValue) => {
  if (!newValue) {
    isMenuAlreadyOnHover.value = false
  }
})

watch(isDesktopNavigationAreaOnHover, (newValue) => {
  if (newValue) {
    emit("onCloseMenuMega")
    isMenuAlreadyOnHover.value = false
  }
})

watch(isLoginAreaOnHover, (newValue) => {
  if (newValue) {
    emit("onCloseMenuMega")
    isMenuAlreadyOnHover.value = false
  }
})

watch(isDividerAreaOnHover, (newValue) => {
  if (newValue) {
    emit("onCloseMenuMega")
    isMenuAlreadyOnHover.value = false
  }
})

onMounted(() => {
  isLoading.value = false
})
</script>

<template>
  <div class="header relative z-[9999]" :class="{ 'md:mb-8': alert?.text }">
    <header
      v-element-visibility="onElementVisibility"
      @mouseover="isHeaderOnHover = true"
      @mouseleave="isHeaderOnHover = false"
      :class="{
        'transform-gpu bg-white drop-shadow-01 transition-all md:translate-y-0': true,
        'translate-y-[-90px]': isPopupVisible
      }"
    >
      <UtilsAboveHeader v-if="shippingMessage" :message="shippingMessage" />

      <div class="padded">
        <div
          class="
            mobile-navignation-area
            flex
            items-center
            justify-between
            gap-5
            py-2
            md:hidden
          "
        >
          <TheHeaderLogo v-if="sm" />

          <div class="flex items-center justify-end">
            <div class="py-[11.5px]">
              <UtilsIcon
                fetch-priority="high"
                :preload="true"
                name="UserLogged.svg"
                v-if="isLogged"
                @click="handleClickAvatar"
                class="login-icon"
              />

              <UtilsIcon
                fetch-priority="high"
                :preload="true"
                name="User.svg"
                v-else
                class="login-icon"
                @click="navigateToLoginSignupPage"
              />
            </div>

            <UtilsButton
              :to="appPaths.savedForLater"
              theme="link-black"
              class="
                custom-btn-mouse-bold-bg-transparent
                justify-center
                hover:underline
              "
            >
              <template #icon>
                <UtilsIcon
                  fetch-priority="high"
                  :preload="true"
                  color="green-500"
                  name="OutlineHeart.svg"
                  class="h-6 w-6"
                />
              </template>
            </UtilsButton>

            <ClientOnly>
              <UtilsButtonCart
                class="w-[80px] justify-center"
                :preload-icons="true"
                :quantity="cartQuantity"
                :to="customPaths.cart"
              />
              <UtilsIcon
                fetch-priority="high"
                :preload="true"
                name="HamburgerMenu.svg"
                class="mx-2 h-6 w-6 cursor-pointer text-green-500"
                @click="handleOpenSideBar"
              />
              <template #fallback>
                <SkeletonAnimatedPlaceholder
                  height="24px"
                  width="80px"
                  borderRadius="8px"
                />
                <SkeletonAnimatedPlaceholder
                  class="mx-2 py-[11.5px]"
                  height="24px"
                  width="24px"
                  borderRadius="8px"
                />
              </template>
            </ClientOnly>
          </div>
        </div>

        <div
          ref="desktopNavigationArea"
          class="
            desktop-navignation-area
            md:flex
            md:min-h-[75px]
            md:items-center
            md:justify-between
            md:gap-4
            md:py-3
            lg:gap-10
          "
        >
          <TheHeaderLogo v-if="!sm" class="hidden min-w-[160px] md:block" />

          <div v-if="isHomePage || showSearchBar" class="flex-1">
            <AlgoliaSearchbar class="md:min-w-[400px]" />
          </div>

          <div class="hidden items-center gap-1 md:flex md:gap-2 lg:gap-6">
            <UtilsButton
              class="
                custom-btn-mouse-rounded-full
                min-w-[80px]
                uppercase
                text-strawberry-100
                hover:underline
                lg:w-[165px]
              "
              :text="$t('navigation.header.discountCode')"
              @click="
                () => handleOpenDiscount($t('navigation.header.discountCode'))
              "
            >
              <template #icon>
                <UtilsIcon
                  fetch-priority="high"
                  :preload="true"
                  name="Label.svg"
                  class="h-5 w-5"
                />
              </template>
            </UtilsButton>

            <UtilsButton
              class="
                custom-btn-mouse-bg-transparent
                min-w-[80px]
                uppercase
                hover:underline
                lg:w-[172px]
              "
              :text="$t('navigation.header.whereIsMyOrder')"
              @click="
                () =>
                  navigateToFindOrder($t('navigation.header.whereIsMyOrder'))
              "
            >
              <template #icon>
                <UtilsIcon
                  fetch-priority="high"
                  :preload="true"
                  name="Car.svg"
                  class="h-5 w-5"
                />
              </template>
            </UtilsButton>

            <UtilsButton
              class="
                custom-btn-mouse-bg-transparent
                min-w-[80px]
                uppercase
                hover:underline
                lg:w-[120px]
              "
              :text="$t('navigation.header.needHelp')"
              @click="
                () => handleOpenNeedHelp($t('navigation.header.needHelp'))
              "
            >
              <template #icon>
                <UtilsIcon
                  fetch-priority="high"
                  :preload="true"
                  name="Headphones.svg"
                  class="h-5 w-5"
                />
              </template>
            </UtilsButton>
          </div>
        </div>

        <div
          class="
            desktop-menu-items
            hidden
            pb-2
            pt-1
            md:flex
            md:items-center
            md:justify-between
            md:gap-2
            lg:gap-5
          "
        >
          <div class="flex items-center gap-x-3">
            <div class="flex gap-x-5">
              <UtilsButton
                @click="emit('onOpenMenuSide')"
                :text="$t('navigation.header.all')"
                theme="ghost-green"
                class="custom-btn-beaver-bg-transparent"
              >
                <template #icon>
                  <div class="h-[30px] w-[30px] shrink-0">
                    <UtilsIcon
                      fetch-priority="high"
                      :preload="true"
                      name="HamburgerMenu.svg"
                      color="green-main"
                    />
                  </div>
                </template>
              </UtilsButton>

              <nav
                class="
                  flex
                  max-h-full
                  flex-wrap
                  items-center
                  gap-x-5
                  overflow-hidden
                "
              >
                <UtilsButton
                  v-for="(item, index) in menuLinks"
                  ref="hoverElement"
                  :key="index"
                  :text="item.label"
                  :path="`/c-${item.path}`"
                  :theme="
                    index === activeMenuIndex ? 'ghost-green' : item?.theme
                  "
                  class="
                    custom-btn-beaver-bg-transparent
                    header-btn
                    flex
                    h-full
                    items-center
                  "
                  @click="() => handleGoToCategory(item.label)"
                  @mouseenter="startHover(item, index)"
                  @mouseleave="endHover"
                />
              </nav>
            </div>

            <UtilsButton
              v-if="customButton"
              :text="customButton.label"
              :path="customButton.path"
              class="custom-btn-beaver-semibold rounded-lg"
              :style="{
                backgroundColor: `${customButton.backgroundColor}`,
                color: `${customButton.textColor}`
              }"
              :theme="`custom`"
              @click="() => handleGtm(customButton!.label)"
            >
              <template #icon v-if="customButton.theme">
                <component
                  v-if="customButton.theme"
                  class="h-6 w-6"
                  :is="iconsMap[customButton.theme]"
                />
              </template>
            </UtilsButton>
          </div>

          <div ref="dividerArea" class="divider" />

          <ClientOnly>
            <div ref="loginArea" class="flex gap-6 md:items-center lg:gap-6">
              <DisplayedUsername
                v-if="isLogged"
                :handleClick="handleClickAvatar"
                showPlaceholder
                class="
                  inline-flex
                  w-max
                  max-w-[160px]
                  shrink-0
                  cursor-pointer
                  items-center
                  gap-1
                  md:m-0
                  md:p-0
                "
                iconClass="md:h-8 md:w-8"
                textClass="md:mouse-semibold text-green-500 hover:underline"
              />

              <UtilsButton
                v-else
                :to="appPaths.loginSignup"
                :text="$t('navigation.header.logIn')"
                :truncateText="false"
                theme="link-black"
                class="
                  custom-btn-mouse-bold-bg-transparent
                  w-[80px]
                  justify-center
                  hover:underline
                "
              >
                <template #icon>
                  <UtilsIcon
                    fetch-priority="high"
                    :preload="true"
                    name="User.svg"
                    class="h-8 w-8 cursor-pointer text-green-500"
                  />
                </template>
              </UtilsButton>

              <UtilsButton
                :to="appPaths.savedForLater"
                :text="$t('navigation.header.savedForLater')"
                theme="link-black"
                :truncateText="false"
                class="
                  custom-btn-mouse-bold-bg-transparent
                  w-[90px]
                  justify-center
                  hover:underline
                "
              >
                <template #icon>
                  <UtilsIcon
                    fetch-priority="high"
                    :preload="true"
                    name="OutlineHeart.svg"
                    color="green-500"
                    class="h-8 w-8 cursor-pointer text-green-500"
                  />
                </template>
              </UtilsButton>

              <UtilsButtonCart
                v-if="statusMiniCart"
                :quantity="cartQuantity"
                :preload-icons="true"
                class="w-[84px]"
              />
              <UtilsButtonCart
                v-else
                :quantity="cartQuantity"
                :preload-icons="true"
                class="w-[84px]"
                @click="emit('onOpenMiniCart')"
              />
            </div>
            <template #fallback>
              <div class="flex h-[51px] gap-6 md:items-center lg:gap-6">
                <SkeletonAnimatedPlaceholder
                  height="32px"
                  width="80px"
                  borderRadius="8px"
                />
                <SkeletonAnimatedPlaceholder
                  height="32px"
                  width="80px"
                  borderRadius="8px"
                />
                <SkeletonAnimatedPlaceholder
                  borderRadius="8px"
                  height="32px"
                  width="84px"
                />
              </div>
            </template>
          </ClientOnly>
        </div>
      </div>

      <HeaderUtilsAlert
        v-if="alert?.text && isScrollingUp"
        class="
          z-[-999]
          w-full
          transition-transform
          duration-300
          ease-in-out
          md:absolute
          md:-bottom-8
        "
        :class="{ 'md:hidden': !showAlert }"
        v-bind="alert"
      />
    </header>
  </div>
</template>

<style lang="scss" scoped>

.login-icon {
  @apply mr-2 h-6 w-6 flex-shrink-0 cursor-pointer text-green-500;
}

.divider {
  @apply h-10 w-[1px] flex-shrink-0 rounded-full bg-black-20;
}

</style>
