export default () => {
  const currentScroll = ref(window && window.top ? window.top.scrollY : 0)

  const scrollLock = () => {
    currentScroll.value = window && window.top ? window.top.scrollY : 0
    document.body.classList.add("noscroll")
    document.body.style.top = "var(--st,-" + currentScroll.value + "px)"
    return true
  }

  const scrollUnlock = () => {
    document.body.classList.remove("noscroll")
    document.body.style.top = ""
    document.documentElement.scrollTop = currentScroll.value
    return false
  }

  return {
    scrollLock,
    scrollUnlock
  }
}
