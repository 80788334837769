<script setup lang="ts">
import { connect as connectImage } from "~/connectors/fragments/WrapperImage"
import { discountPercentage } from "~/utils/formatters"
import type { CardProps } from "../ProductTile/CartPreview/Card.props"
import { Sku } from "@commercelayer/sdk"
import { LineItem_ } from "~/composables/useOrder"
import { useGAEntity } from "#imports"
import { checkImage } from "~/utils/checkImage"
import { ProductAlgolia } from "utils/mapProducts"

const {
  freeGiftPromotions,
  lineItems,
  shippingInfos,
  decreaseQuantity,
  addToCart
} = useCart()

const { mapCartProductsFactory } = useMapCart()

const { removeQuantityFromCart, updateCartList } = useGA4SiteEvents()

const { data: result } = useLazyFetch(
  `/api/algolia/getRecommendations/recommend-4/param`,
  {
    server: false
  }
)

defineEmits<{ (e: "onClose"): void }>()

const mapMiniCartProduct = (
  item: LineItem_<Sku>
): Omit<CardProps, "isFreeGift" | "quantity"> => ({
  id: item.id,
  productImage: connectImage(checkImage(item?.metadata?.product_image)),
  title: item.name!,
  discountPercetage: discountPercentage(
    item.item.prices![0].amount_float!,
    item.item.prices![0].compare_at_amount_float!
  ),
  oldPrice: item.item.prices![0].compare_at_amount_float,
  currentPrice: item.item.prices![0].amount_float!,
  path: item?.metadata?.slug,
  productCode: item.sku_code!,
  inStock: item?.item?.inventory?.available
})

const miniCartProducts = computed(() =>
  mapCartProductsFactory(mapMiniCartProduct)(freeGiftPromotions.value)(
    lineItems.value
  )
)

onMounted(() => {
  updateCartList()
})

watch(lineItems, updateCartList)

const onRemoveFromCartEvent = (
  id: string,
  productCode: string,
  quantity: number
) => {
  decreaseQuantity(id, quantity)

  removeQuantityFromCart(productCode, {
    quantity
  })
}

const suggestionProducts = computed(() => {
  const products: ProductAlgolia[] = _flattenDeep(
    result.value?.results?.map((item) => item.hits) ?? []
  )
  return mapProductsAlgolia(products)
})
</script>

<template>
  <TheMiniCart
    @onClose="$emit('onClose')"
    @onRemoveFromCart="
      ({ id, quantity, productCode }) =>
        onRemoveFromCartEvent(id, productCode, quantity)
    "
    :cartProducts="miniCartProducts"
    :recommendedProducts="suggestionProducts"
    :freeShipmentOver="shippingInfos?.shippingInfos?.value?.freeShipping"
  />
</template>
