<script setup lang="ts">
import { ShippingCostProps, iconsMap } from "./ShippingCost.props"
import { formatMoney } from "~/utils/formatters"

const props = defineProps<ShippingCostProps>()

const isFree_ = computed(() => typeof props.isFree === "boolean")
</script>

<template>
  <div class="cart-utils-shipping-cost relative text-tiffany-400">
    <Transition mode="out-in">
      <div :key="`${isFree_}-shipping`">
        <div
          :class="isFree_ ? 'tiffany-gradient' : 'yellow-gradient'"
          class="opacity contain"
        >
          <component
            :is="iconsMap[isFree_ ? 'party' : 'delivery']"
            class="h-6 w-8 align-middle md:order-3"
          />
          <p class="mouse-bold flex-auto align-middle md:flex md:flex-none">
            {{ $t("cart.shippingTotal") }}
          </p>
          <p class="pig-bold align-middle">
            {{ isFree_ ? $t("cart.gratis") + "!" : formatMoney(price) }}
          </p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>

.contain {
  @apply flex h-10 items-center justify-between gap-x-2 rounded-lg px-4 md:justify-end;
}
.yellow-gradient {
  background-image: linear-gradient(to right, #fef8e900, #fef8e9);
}
.tiffany-gradient {
  background-image: linear-gradient(to right, #d0ebef00, #d0ebef);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
