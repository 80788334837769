<script lang="ts" setup>
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { useStatefulCookie } from "~/composables/useStatefulCookie"
import { PROFILE_SLUGS } from "~/utils/constants"
import type { WithContext, WebSite } from "schema-dts"
import type { NotificationAlertProps } from "~/components/NotificationAlert.props"
import type { DiscountCodeProps } from "components/Card/DiscountCode.props"

const userStore = useUserStore()
const authToken = useStatefulCookie("authToken")
const { user } = storeToRefs(userStore)
const { addNotification } = useNotification()
const { t } = useI18n()
const route = useRoute()
const welcomeID = ref("WELCOME_MODAL")

const welcomeCookie: any = useStatefulCookie("welcome-visit", {
  default: () => false,
  maxAge: 5184000
})

const myAccountArea = computed(
  () => route.fullPath.includes(PROFILE_SLUGS.ROOT) ?? false
)
const WELCOME_WORD = "benvenuto"
const registrationCookie = useStatefulCookie("show-registration-modal")
const newsletterCookie = useStatefulCookie("show-newsletter-modal")
const { discountCodes } = useDiscountCodes()
const registrationDiscountCode = ref<DiscountCodeProps | null>(null)

watch(discountCodes, (newValue) => {
  const welcomeDiscountCodes = discountCodes.value?.filter(
    (e: DiscountCodeProps) =>
      e?.status == "ready" &&
      e?.discountCode?.toLowerCase()?.includes(WELCOME_WORD) &&
      e?.symbol === "%"
  )

  registrationDiscountCode.value = welcomeDiscountCodes[0] ?? null
  if (
    registrationCookie.value === true &&
    authToken.value &&
    process.client &&
    !!registrationDiscountCode.value
  ) {
    openModal("welcome-discount")
    registrationCookie.value = undefined
  }
})

onMounted(async () => {
  if (!myAccountArea.value) {
    const checkSession = await useCheckSessionUser()
    if (authToken.value) userStore.updateNewsletterSubscription(authToken.value)
    if (typeof checkSession === "object" && !user.value?.email)
      userStore.updateUserData(checkSession)
    if (
      authToken.value &&
      checkSession?.has_password !== true &&
      newsletterCookie.value
    ) {
      openModal("modal-newsletter")
      newsletterCookie.value = false
    }
  }

  //LA MODALE DI BENVENUTO E' STATA RIMOSSA
  // TODO: rimuovere compltamente il codice
  //   if (!welcomeCookie.value) {
  //     openModal(welcomeID.value)
  //     welcomeCookie.value = true
  //   }
})

const { openModal, closeModal } = useModal()

if (process.server) {
  const getMerchantReviewsAverages = await $fetch(
    `/api/feedaty/getReviewsAverages`,
    {
      method: "GET"
    }
  )

  const ratings = {
    reviews: getMerchantReviewsAverages?.Data?.Merchant?.RatingsCount ?? 0,
    rating: getMerchantReviewsAverages?.Data?.Merchant?.AverageValue ?? 0
  }

  const baseUrl = useRuntimeConfig().public.BASE_URL_APP
  const jsonLdSearchbar = computed<WithContext<WebSite>>(() => ({
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: baseUrl,
    potentialAction: {
      "@type": "SearchAction",
      target: {
        "@type": "EntryPoint",
        urlTemplate: `${baseUrl}/ricerca?query={search_term_string}`
      },
      "query-input": "required name=search_term_string"
    }
  }))

  const jsonLdOrganization = computed(() => ({
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://www.topfarmacia.it/#address-and-contact-container",
    url: "https://www.topfarmacia.it/",
    name: "Top Farmacia",
    logo: `${baseUrl}/images/LogoGreen1.svg`,
    address: {
      "@type": "PostalAddress",
      streetAddress: "Via IV Novembre, 6 (Località Cadriano)",
      addressLocality: "Granarolo dell'Emilia",
      addressCountry: "IT",
      addressRegion: "BO",
      postalCode: "40057"
    },
    aggregateRating: {
      "@type": "AggregateRating",
      "@id": "https://www.topfarmacia.it/#cms-component-reviews",
      sameAs: "https://www.feedaty.com/recensioni/topfarmacia",
      ratingValue: ratings.rating,
      reviewCount: ratings.reviews
    },
    areaServed: {
      "@type": "Country",
      name: "Italy"
    },
    email: "",
    legalName: "Top Parafarmacia S.p.A.",
    location: {
      "@type": "PostalAddress",
      streetAddress: "Via IV Novembre, 6 (Località Cadriano)",
      addressLocality: "Granarolo dell'Emilia",
      addressCountry: "IT",
      addressRegion: "BO",
      postalCode: "40057"
    },
    taxID: "03400011205",
    telephone: "+39051759630",
    vatID: "03400011205"
  }))

  useHead({
    script: [
      {
        type: "application/ld+json",
        children: JSON.stringify(cleanJsonLdObject(jsonLdSearchbar.value))
      },
      {
        type: "application/ld+json",
        children: JSON.stringify(cleanJsonLdObject(jsonLdOrganization.value))
      }
    ]
  })
}

const handleAddToCartNotification = (productName: string) => {
  const notification: NotificationAlertProps = {
    id: Math.random(),
    type: "success",
    notificationAlertText: t(
      "dialogsAndModals.addToCart.notificationAddToCart",
      { productName: productName }
    ),
    isWithCloseIcon: true
  }
  addNotification(notification)
}

const wrapperPageContent = ref(null)
const { height: nuxtPageHeight } = useElementBounding(wrapperPageContent)
const pageHeight = useState<number>(
  "wrapper-page-content-height-throttled",
  () => 0
)

watchThrottled(nuxtPageHeight, (newVal) => (pageHeight.value = newVal), {
  throttle: 3000
})
</script>

<template>
  <div>
    <TheTopNavigation />
    <DialogsAndModalsUtilsModal
      id="welcome-discount"
      :enable-on-click-outside="true"
      fullscreen
    >
      <DialogsAndModalsWelcomeDiscount
        :coupon="registrationDiscountCode"
        @on-close="closeModal('welcome-discount')"
      />
    </DialogsAndModalsUtilsModal>

    <DialogsAndModalsUtilsModal
      id="change-coupon"
      :enable-on-click-outside="true"
    >
      <DialogsAndModalsChangeCoupon @on-close="closeModal('change-coupon')" />
    </DialogsAndModalsUtilsModal>

    <DialogsAndModalsUtilsModal
      id="modal-newsletter"
      :enable-on-click-outside="true"
    >
      <DialogsAndModalsNewsletter
        @on-subscription="closeModal('modal-newsletter')"
        @on-close="closeModal('modal-newsletter')"
      />
    </DialogsAndModalsUtilsModal>
    <DialogsAndModalsUtilsModal
      id="similar-products"
      :enable-on-click-outside="true"
    >
      <OrganismsCartModalsSimilarProducts
        @notification="handleAddToCartNotification($event)"
      />
    </DialogsAndModalsUtilsModal>

    <DialogsAndModalsUtilsModal
      id="notify-product-avaibility"
      :enable-on-click-outside="false"
    >
      <DialogsAndModalsOrganismNoticeProductAvailable
        id="notify-product-avaibility"
        :title="$t('dialogsAndModals.noticeProduceAvailable.title')"
        :description="$t('dialogsAndModals.noticeProduceAvailable.description')"
      />
    </DialogsAndModalsUtilsModal>

    <div
      class="wrapper-page-content relative overflow-x-hidden"
      :class="[{ 'bg-grey-main': myAccountArea }]"
      ref="wrapperPageContent"
    >
      <div
        v-if="myAccountArea"
        class="grid-standard negative-margined md:mx-0 md:items-start"
      >
        <MenuMyAccount
          class="hidden md:col-span-3 md:ml-[-71px] md:block lg:ml-[-228px]"
        />
        <div class="col-span-full md:col-span-9 md:mb-11 md:mt-7">
          <slot />
        </div>
      </div>
      <slot v-else />
    </div>

    <TheBottomNavigation />
  </div>
</template>
