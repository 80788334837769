<script setup lang="ts">
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"

const emit =
  defineEmits<{
    (e: "on-subscription"): void
    (e: "on-close"): void
  }>()

const { refreshDiscountCodes } = useDiscountCodes()
const { updateUserSubscriptionNewsletter, updateNewsletterSubscription } =
  useUserStore()
const { user } = storeToRefs(useUserStore())
const isLogged = useIsLogged()
const { cart } = useCart()
const subscribe = async () => {
  const { error } = await updateUserSubscriptionNewsletter(isLogged.value, true)
  if (!error.value) {
    //Refresh discount codes
    await updateNewsletterSubscription(isLogged.value)
    await refreshDiscountCodes()
    emit("on-subscription")
  }
}

const bgImage = {
  image: {
    url: "/i5oh5hzamkwo/6TdcFY9wkBG9LUJjetGxm/4442a9c64d23c4079ab4fe48a4b2cd5d/cover-modal-newsletter.webp",
    width: 1464,
    height: 350
  }
}
</script>

<template>
  <DialogsAndModalsStandard
    @on-close="emit('on-close')"
    :title="$t('dialogsAndModals.newsletter.title')"
    class="max-w-[460px]"
  >
    <template #content>
      <div class="px-6 pb-6 pt-4">
        <div
          class="
            relative
            z-10
            mb-4
            overflow-hidden
            rounded-lg
            px-4
            py-5
            text-center
            md:px-12
          "
        >
          <UtilsWrapperImage
            v-if="bgImage"
            v-bind="bgImage"
            :key="bgImage.image.url"
            class="
              wrapper-image-full-container-centered
              absolute
              inset-0
              -z-10
              overflow-hidden
            "
          />
          <div
            class="
              absolute
              inset-0
              -z-10
              h-full
              w-full
              bg-tiffany-60
              opacity-80
            "
          />
          <h2 class="mouse -z-10 uppercase">
            {{ $t("services.newsletter.caption") }}
          </h2>

          <p class="elephant-bold py-2">
            {{ $t("services.newsletter.title") }}
          </p>

          <UtilsMarkdown
            :content="$t('services.newsletter.discountText')"
            class="beaver markdown__link-underlined"
          />
        </div>

        <UtilsNewsletterForm
          :email="user?.email"
          :unsubscribe-text="$t('services.newsletter.unsubscribeText')"
          @on-subscription="subscribe"
        />
      </div>
    </template>
  </DialogsAndModalsStandard>
</template>
