import { mapOrderStatus } from "~/utils/orders"
import { useUserStore } from "~/stores/user"
import { pushMenuClickCtaEvent } from "~/utils/analytics/clickCta"

const retrieveOngoingOrders = async (userId: string) => {
  const { $cl } = useNuxtApp()

  return await $cl.customers
    .orders(userId, {
      include: ["shipments", "shipments.parcels"],
      filters: {
        status_not_in: "draft,pending,cancelled"
      }
    })
    .then((data) => {
      return data
        ? data
            .map((singleOrder: any) => {
              const lastShipment =
                singleOrder.shipments && singleOrder.shipments.length > 0
                  ? singleOrder.shipments.at(-1)
                  : []

              return {
                orderId: singleOrder.id,
                orderStatus: mapOrderStatus(
                  singleOrder.status,
                  lastShipment,
                  singleOrder?.reference_origin
                ),
                orderReference: singleOrder.reference
              }
            })
            .filter(
              (singleOrderMapped: any) =>
                singleOrderMapped.orderStatus === "PendingDelivery" ||
                singleOrderMapped.orderStatus === "Editable"
            )
        : null
    })
    .catch((e: Error) => {
      console.log(e)
      return null
    })
}

export const navigateToFindOrder = async (label: string) => {
  pushMenuClickCtaEvent(label)

  const isLogged = useIsLogged()

  let path: string
  if (!isLogged.value) {
    path = customPaths.findOrder
  } else {
    const userStore = useUserStore()
    const ongoingOrders = await retrieveOngoingOrders(userStore.userId)
    if (ongoingOrders?.length === 1) {
      path = `${customPaths.trackOrder}/${userStore.user?.email}/${ongoingOrders[0].orderReference}`
    } else {
      path = customPaths.profile.orders
    }
  }
  navigateTo(path)
}
