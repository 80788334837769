<script setup lang="ts">
import { ButtonCartProps } from "./ButtonCart.props"

const { t } = useI18n()

withDefaults(defineProps<ButtonCartProps>(), {
  quantity: 0
})
</script>

<template>
  <div class="flex items-center">
    <UtilsButton
      class="
        custom-btn-mouse-bold-bg-transparent
        button-cart
        shrink-0
        hover:underline
      "
      theme="link-black"
      :text="t('navigation.header.cart')"
      :to="to"
    >
      <template #icon>
        <div v-if="!!quantity" class="relative">
          <UtilsIcon
            name="ShoppingCartFull.svg"
            color="green-500"
            :preload="preloadIcons ? true : undefined"
            :fetch-priority="preloadIcons ? 'high' : undefined"
            class="h-6 w-6 cursor-pointer md:h-[30px] md:w-[30px]"
          />
          <span
            class="
              mouse-bold
              md:beaver-bold
              absolute
              left-[calc(50%_+_1px)]
              top-[-2px]
              mx-auto
              -translate-x-1/2
              !text-orange-main
              md:left-[calc(50%_+_2px)]
              md:top-0
            "
          >
            {{ quantity }}
          </span>
        </div>
        <UtilsIcon
          name="ShoppingCartEmpty.svg"
          color="green-500"
          :preload="preloadIcons ? true : undefined"
          :fetch-priority="preloadIcons ? 'high' : undefined"
          v-else
          class="h-6 w-6 cursor-pointer md:h-[30px] md:w-[30px]"
        />
      </template>
    </UtilsButton>
  </div>
</template>
