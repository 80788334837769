export const checkImage = (image) => {
  if (!image) {
    return getImageData({
      altText: "Fallback Image",
      url: "/i5oh5hzamkwo/5k6jyVWV4xYTmuq5MehTIg/8e5a3ae1ad65be21dd5f960ec0e09757/DefaultProductImage.svg",
      width: 300,
      height: 300
    })
  }
  return image
}
