import BannerDelivery from "~icons/icons/BannerDelivery.svg"
import Party from "~icons/icons/Party.svg"

export const iconsMap = {
  delivery: BannerDelivery,
  party: Party
}

export type ShippingCostProps = {
  isFree: boolean | number
  price: number
}
