<script setup lang="ts">
import { MiniCartProps } from "./TheMiniCart.props"
import { formatMoney } from "~/utils/formatters"
import { customPaths } from "~/utils/constants"
import { pushCartClickCtaEvent } from "~/utils/analytics/clickCta"

const { cart } = useCart()

defineProps<MiniCartProps>()

const emit =
  defineEmits<{
    (e: "onClose"): void
    (
      e: "onRemoveFromCart",
      payload: { id: string; quantity: number; productCode: string }
    ): void
  }>()

const handleGtm = (label: string) => {
  pushCartClickCtaEvent(label)
}

const handleGoToCartOrCheckout = (label: string) => {
  emit("onClose")
  handleGtm(label)
}
</script>

<template>
  <div class="mini-cart hidden bg-white md:block">
    <div
      class="
        cart-products
        sticky
        top-0
        z-10
        bg-white
        pb-8
        pl-6
        pt-4
        md:-mr-[71px]
        md:pr-[71px]
        lg:-mr-[228px]
        lg:pr-[228px]
      "
    >
      <div class="mb-4 flex items-center justify-between">
        <div class="flex items-center">
          <h3 class="hyppo-bold">{{ $t("miniCart.myCart") }}</h3>
          <p v-if="cart?.skus_count" class="ml-2">
            {{
              $t("miniCart.count", cart?.skus_count, {
                count: cart?.skus_count
              })
            }}
          </p>
        </div>
        <UtilsIcon
          name="Close.svg"
          @click="$emit('onClose')"
          class="h-6 w-6 cursor-pointer"
        />
      </div>
    </div>

    <div
      class="
        recommended-products
        bg-white
        pb-8
        pl-6
        md:-mr-[71px]
        md:pr-[71px]
        lg:-mr-[228px]
        lg:pr-[228px]
      "
    >
      <div v-if="cartProducts?.length">
        <ProductTileCartPreviewCard
          v-for="(product, i) in cartProducts"
          :class="{ 'mb-4 lg:mb-6': i !== cartProducts.length - 1 }"
          :key="`cart-product-${product.productCode}`"
          v-bind="product"
          @onRemoveFromCart="
            $emit('onRemoveFromCart', {
              id: product.id,
              quantity: product.quantity,
              productCode: product.productCode
            })
          "
          @on-close="$emit('onClose')"
        />

        <div
          v-if="freeShipmentOver !== true"
          class="mt-6 flex items-center rounded-lg bg-yellow-10 px-3 py-4"
        >
          <UtilsIcon name="Bell.svg" color="strawberry-main" class="mr-2" />
          <div class="mouse">
            <UtilsMarkdown
              :content="
                $t('miniCart.freeShipmentMessage', {
                  freeShipmentMoney: freeShipmentOver
                    ? formatMoney(freeShipmentOver)
                    : ''
                })
              "
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col items-center gap-4">
          <UtilsIcon name="EmptyCart.svg" class="h-auto w-[128px]" />

          <h4 class="pig-bold md:mammoth-bold">
            {{ $t("cart.whereToStartSection.title") }}
          </h4>
        </div>
      </div>
    </div>

    <div
      class="
        recommended-products
        bg-grey-main
        pb-8
        pl-6
        pt-4
        md:-mr-[71px]
        md:pr-[71px]
        lg:-mr-[228px]
        lg:pr-[228px]
      "
    >
      <div v-if="recommendedProducts?.length">
        <h4 class="beaver-bold mb-4">
          {{ $t("miniCart.recommendedProduct") }}
        </h4>
        <div>
          <GAList
            id="minicart-recommended"
            name="Minicart: raccomandati"
            entity="modalCartProducts"
          >
            <GAItem id="minicart-recommended-item" :item="recommendedProducts">
              <GAViewItemList method="carrello"> </GAViewItemList>
            </GAItem>
          </GAList>
          <ProductTileCartPreviewCard
            v-for="(product, i) in recommendedProducts"
            :class="{ 'mb-4 lg:mb-6': i !== recommendedProducts.length - 1 }"
            :key="`recommended-product-${i}`"
            listName="Minicart: raccomandati"
            v-bind="product"
            :is-free-gift="false"
            @on-close="$emit('onClose')"
          />
        </div>
      </div>
    </div>

    <div
      class="
        cta-container
        sticky
        bottom-0
        bg-white
        py-4
        pl-6
        shadow-01
        md:-mr-[71px]
        md:pr-[71px]
        lg:-mr-[228px]
        lg:pr-[228px]
      "
    >
      <UtilsButton
        theme="outlined-green"
        :to="customPaths.cart"
        @click="
          () =>
            handleGoToCartOrCheckout($t('dialogsAndModals.addToCart.seeCart'))
        "
        :text="$t('dialogsAndModals.addToCart.seeCart')"
        fluid
        class="mb-3 block lg:mb-4"
      />
      <UtilsButton
        :text="$t('dialogsAndModals.addToCart.goToPay')"
        fluid
        :to="cartProducts?.length ? customPaths.checkout : ''"
        @click="
          () =>
            handleGoToCartOrCheckout($t('dialogsAndModals.addToCart.goToPay'))
        "
        :isDisabled="!cartProducts?.length"
      />
    </div>
  </div>
</template>
