<script setup lang="ts">
import { LeftAsideProps } from "./LeftAside.props"
import { sm } from "~/utils/breakpoints"

const props = withDefaults(defineProps<LeftAsideProps>(), {
  columns: 5
})

const { y } = useWindowScroll()

const isHeaderVisible = useState("header")

const positionTop = computed(() => {
  if (!isHeaderVisible.value) {
    return 0
  }

  if (!props.headerAlertExist) {
    return sm ? 150 : 160
  }

  if (y.value > 100) {
    return sm ? 180 : 192
  }

  // return y.value > 100 ? 132 : 192
})

const leftAsideRef = ref(null)
const { top } = useElementBounding(leftAsideRef)
const { height } = useWindowSize()
</script>

<template>
  <div class="md:block">
    <Transition name="opacity">
      <UtilsOverlayModal v-if="isOpen" />
    </Transition>
    <Transition name="slide-left">
      <div
        v-if="isOpen"
        ref="leftAsideRef"
        class="grid-standard fixed right-0 z-[999] w-full"
        :style="`height: ${height - top}px; top:${positionTop}px`"
      >
        <div
          class="
            relative
            -mx-4
            overflow-y-auto
            bg-white
            pr-4
            md:col-end-13
            md:-mr-[71px]
            md:ml-0
            md:pr-[71px]
            lg:-mr-[228px]
            lg:pr-[228px]
          "
          :class="`col-span-${columns}`"
        >
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>
