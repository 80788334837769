<script setup lang="ts">
import { HamburgerMenuProps } from "./HamburgerMenu.props"
defineProps<HamburgerMenuProps>()

const emit =
  defineEmits<{
    (e: "onCloseMenu"): void
  }>()

const handleClose = () => {
  emit("onCloseMenu")
}
</script>

<template>
  <div
    class="
      hamburger-menu
      fixed
      inset-0
      z-[999]
      overflow-x-hidden
      bg-white
      scrollbar-hide
    "
    :class="[{ 'hidden w-[360px] md:block': isSidebarDesktop as boolean }, { 'block w-full md:hidden': !isSidebarDesktop }]"
  >
    <ClientOnly>
      <MenuSidebar
        :username="username"
        :isLogged="isLogged"
        :search-settings="searchSettings"
        :categories="categories"
        :otherLinks="otherLinks"
        @onClose="handleClose"
      />
    </ClientOnly>
  </div>
</template>
