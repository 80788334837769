<script setup lang="ts">
import type { DiscountCodeProps } from "components/Card/DiscountCode.props"
import type { NotificationAlertProps as Notification } from "~/components/NotificationAlert.props"

const props =
  defineProps<{
    coupon: DiscountCodeProps
  }>()

const emit =
  defineEmits<{
    (e: "onClose"): void
  }>()

const isDiscountApplied = ref(false)

const bgImage = getImageData({
  altText: "Confetti image",
  url: "/i5oh5hzamkwo/Iu4GnahckmTPhP1TiezMs/284b6f8481fe70de70b1172b94c3a769/cover-image-welcome-modal.webp",
  width: 390,
  height: 120
})
const discountCode = ref(props.coupon.discountCode ?? "di benvenuto")
const discountPercentage = ref(props.coupon.discount ?? 5)

const { t } = useI18n()
const { applyCoupon } = useCart()
const { addNotification } = useNotification()

const notificationSuccess: Notification = {
  id: Math.random(),
  type: "success",
  notificationAlertText: t("discounts.codeAppliedSuccessfully"),
  isWithCloseIcon: true
}

const notificationError: Notification = {
  id: Math.random(),
  type: "danger",
  notificationAlertText: t("discounts.codeAppliedError"),
  isWithCloseIcon: true
}

const handleDiscountCode = async () => {
  isDiscountApplied.value = true

  try {
    await applyCoupon(discountCode.value)

    addNotification(notificationSuccess)

    emit("onClose")
  } catch {
    addNotification(notificationError)
  }
}
</script>

<template>
  <DialogsAndModalsStandard
    :title="$t('dialogsAndModals.welcome.welcome')"
    class="md:max-w-[390px] lg:max-w-[470px]"
    @on-close="$emit('onClose')"
  >
    <template #content>
      <div class="relative z-10 overflow-hidden px-7 py-5 text-center">
        <UtilsWrapperImage
          v-if="bgImage"
          :="bgImage"
          :key="bgImage.image.url"
          class="wrapper-image-full-container-centered absolute inset-0 -z-10"
        />

        <p
          class="
            hyppo-bold
            flex flex-col
            text-center
            uppercase
            text-negative-main
          "
        >
          <span v-if="discountPercentage" class="dyno-bold">
            {{ `-${discountPercentage}%` }}
          </span>

          {{ $t("dialogsAndModals.welcome.onFirstOrder") }}
        </p>
      </div>

      <div
        class="
          beaver
          flex flex-col
          items-center
          gap-4
          p-4
          text-center
          md:px-10
          md:pb-6
        "
      >
        <UtilsMarkdown
          :content="
            $t('dialogsAndModals.welcome.discount', {
              discountPercentage: discountPercentage
            })
          "
        />

        <div class="h-11 w-full px-3 md:px-4">
          <UtilsButton
            v-if="isDiscountApplied"
            :text="$t('dialogsAndModals.welcome.applied')"
            class="custom-btn-positive-100 w-full cursor-pointer text-white"
            order="last"
          >
            <template #icon>
              <UtilsIcon name="Checkmark.svg" color="white" class="h-5 w-5" />
            </template>
          </UtilsButton>

          <UtilsButton
            v-else
            @click="handleDiscountCode"
            :text="$t('dialogsAndModals.welcome.apply')"
            class="w-full cursor-pointer"
          />
        </div>

        <UtilsMarkdown
          :content="
            $t('dialogsAndModals.welcome.istruction', { n: discountCode })
          "
        />

        <UtilsButton
          :text="$t('dialogsAndModals.welcome.visit')"
          @click="$emit('onClose')"
          theme="outlined-green"
          class="w-full cursor-pointer px-4"
        />
      </div>
    </template>
  </DialogsAndModalsStandard>
</template>
